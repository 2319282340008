.folio-dialog-container {
    background: white;

    position: fixed;
    padding: 50px;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    display: grid;
    grid-template-columns: minmax(0, 2fr) minmax(0, 3fr);
    grid-template-rows: minmax(0, 3fr) minmax(0, 3fr);
    gap: 40px;
    z-index: 3000;
}

.folio-title {
    margin: 15px 0 20px;
}

.folio-subtitle {
    font-size: 30px;
    font-style: italic;
    font-weight: normal;
    margin: -10px 0 10px;
    line-height: 0.9;
    opacity: 0.4;
}

.top-left {
    grid-area: 1 / 1 / 2 / 2;
    position: relative;
}

.bottom-left {
    grid-area: 2 / 1 / 3 / 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.right {
    grid-area: 1 / 2 / 3 / 3;
    position: relative;
}

.folio-desc {
    display: flex;
}

.folio-desc-text {
    font-size: 17px;
    flex: 1;
}

.folio-desc-intro.small-gill {
    font-size: 12px;
}

.folio-desc-right-space {
    width: 20px;
}

.back-btn {
    height: 65px;
    display: inline-block;
}

.arrow {
    height: 65px;
    margin-left: -3px;
}

@media screen and (max-width: 700px) {

    .folio-dialog-container {
        display: block;
        padding: 30px;
        overflow: auto;
    }

    .folio-dialog-container .top-left {
        margin-top: 20px;
    }

    .folio-dialog-container .bottom-left {
        margin-top: 78px;
        padding-bottom: 30px;
    }

    .folio-dialog-container .right {
        height: 442px;
        margin-top: 65px;
    }

    .folio-dialog-container .slick-dots {
        bottom: -38px;
    }

}